/* Default Styles */

/*Color*/

.primary {
    color: #fff !important;
}

.secondary1 {
    color: green;
}

hr {
    border-color: #fff !important;
}
/*Font styles */

.alignTextLeft {
    text-align: left !important;
}

.upperCase {
    text-transform: uppercase !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-44 {
    font-size: 44px !important;
}

/*Padding */

.p0 {
    padding: 0 !important;
}

.mainContainer {
    padding: 4vh 8vw;
}

h1 {
    color: #333;
}


p {
    color: #666;
}

ul {
    list-style: none;
    padding-left: 0 !important ;

}

ul li {
    color: #fff;
    font-size: 26px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 5px;
}
/* Custom Margin*/
.ml-1 {
    margin-left: 10px;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

/* Custom Padding */
.pt12 {
    padding-top: 12vh;
}

.pt-3 {
     padding-top: 30px;
}

.pt-8 {
    padding-top: 80px;
}

.pb-5 {
    padding-bottom: 50px;
}

.textAlign-center {
    text-align: center;
}


/* Custom Styles */
.custom-content {
    max-width: 600px;
    height:100%;
    margin: 50px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navBar-gradient {
    margin-top: 0;
    background-color: #0753f6 !important;
}

.contact-info {
    display: flex;
}

.phone-number {
    border: 3px solid #fff; /* Border color for the contact info */
    padding: 8px 30px;
    border-radius: 14px;
    margin-left: 14px;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    img {
        padding-right: 10px;
    }
    a {
        color: white;
    }
}

.navbar {
    background-color: transparent;
    transition: background-color 0.3s;
}

.navbar.sticky {
    background-color: #343a40; /* Change the background color when sticky */
}


.dflex {
    display: flex;
}

.justifyContentSpaceAround {
    justify-content: space-around;
}

.alignItemCenter {
    align-items: center;
}

.big-win {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.today-result {
    color: white;
    font-size: 44px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.big-win-image {
    max-height: 50vh;
}

.success-image {
    max-height: 30vh;
}

.container-overlay {
    position: relative;
}

.bottom-image,
.top-image {
    width: 15vw;
    height: auto;
    max-height: 30vh;
    position: absolute;
}

.bottom-image {
    z-index: 1;
}

.top-image {
    z-index: 2;
    top: 20%; /* Adjust the top position as needed */
    left: 30%; /* Adjust the left position as needed */
    /* You can also use right and bottom properties if you prefer */
}

.gPay {
    border-radius: 33px;
    background-color: #fff;
    padding: 10px 55px;
    img {
        height : 25px;
    }
}

.pPay {
    border-radius: 33px;
    background-color: #fff;
    padding: 5px 28px;
       img {
        height : 35px;
    }
}

.date-container {
    position: relative;
    input {
        width: 300px;
        height: 52px;
        padding-right: 25px; /* Adjust based on the icon size */
        padding-left: 40px;
        border-radius: 40px;
        border: 2px solid #fff;
    }
}

.calender-icon {
    position: relative;
    top: 46px;
    left: 20px;
    cursor: pointer;
}

.down-icon {
    position: relative;
    left: 350px;
    bottom: 28px;
    transform: translateY(-50%);
    cursor: pointer;
}

.history {
color: #fff;
font-size: 32px;
font-weight: 700;
font-family: 'Roboto', sans-serif;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between thumbnails */
}

.addressTypo {
    max-width: 325px;
}

.thumbnail {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 18px;
    transition: transform 0.2s ease-in-out;
    img {
    width: 100%;
    height: 408px;
    /* display: block; */
    object-fit: cover;
    border-radius: 18px;
    }
}

.thumbnail:hover {
    transform: scale(1.1); /* Adjust the scale on hover */
}







